
  import { Component, Watch } from 'vue-property-decorator'
  import { Expandable } from '@/components/toolkit/details/row/custom/Expandable'
  import dayjs from 'dayjs'

@Component
  export default class ExpandableLastAppraisals extends Expandable {
  appraisals: Record<string, any>[] = []

  @Watch('value', { immediate: true, deep: true })
  async onValueChanged (val) {
    if (!val?.deal?.auto?.id) return
    const resp = await this.getAppraisalWithAutoId(val.deal.auto.id)

    this.appraisals = resp?.filter(appraisal => appraisal.id !== val.id)
  }

  createdDate (date) {
    const newDate = dayjs(date)
    return newDate.format('DD/MM/YYYY HH:MM')
  }

  status (appraisal) {
    if (!appraisal) return

    if (appraisal?.closingReason?.id) {
      return {
        description: appraisal?.closingReason?.description || appraisal.closingReason.type.description,
        color: appraisal.closingReason.type.color,
        icon: { main: appraisal?.closingReason?.type?.icon?.main || appraisal?.closingReason?.icon?.main },
      }
    }

    return {
      description: appraisal.status.status.description,
      color: appraisal.status.color,
      icon: { main: appraisal.status.icon.main },
    }
  }

  difference (response, expected) {
    return Math.abs((parseInt(response) || 0) - (parseInt(expected) || 0))
  }
  }
